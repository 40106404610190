import React, {useState, useEffect, useCallback} from 'react';
import App from './App';
const _ = require('lodash');
const { debounce } = _;

require('dotenv').config();

function AppContainer() {
    const [imageData, setImageData] = useState([]);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [openImage, setOpenImage] = useState(null);
    var length = 0;

    const getData = () => {
        fetch("/get_image_data", {method: 'POST', body: JSON.stringify({offset: length})})
            .then(res => res.json())
            .then(data => {
                setImageData(existingData => {
                    //trying to check if image has been loaded, not working right
                    if (existingData[existingData.length - 1] === data[data.length - 1]) {
                        return [...existingData];
                    } else {
                        return [...existingData, ...data];
                    }
                });
                length += data.length;
            })
            .catch((e) => {
              console.log(e);
            })
    }
    const debounceLoadData = useCallback(debounce(getData, 250), []);

    const handleOpen = (img) => {
        setIsModalOpen(true);
        setOpenImage(img);
    }
    const handleClose = () => {
        setIsModalOpen(false);
        setOpenImage(null);
    }

    useEffect(() => {
        if (length === 0) {
            debounceLoadData();
        }
        window.addEventListener("scroll", function (e) {
            if (Math.ceil(window.innerHeight + window.scrollY) >= Math.ceil(document.body.offsetHeight)) {
                debounceLoadData();
            }
        }, false);
    }, [])

    return (
        <App
            images={imageData}
            isModalOpen={isModalOpen}
            openImage={openImage}
            bucketName={process.env.REACT_APP_AWS_BUCKET_NAME}
            handleOpen={handleOpen}
            handleClose={handleClose}
        />
    )
}

export default AppContainer;