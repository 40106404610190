import './App.css';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Modal from '@mui/material/Modal';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { CardActionArea } from '@mui/material';

const App = ({
  images,
  isModalOpen,
  openImage,
  bucketName,
  handleOpen,
  handleClose,
}) => {
  return (
    <div
      className="App"
    >
      <header className="App-header">
        The Internet Right Now
      </header>
      <div className="grid-container" id="test">
        {images.map((image) => (
          <div className="grid-item" key={image.id}>
            <Card className="card">
              <CardActionArea
                onClick={() => { handleOpen(image) }}
              >
                {image && (
                  <CardMedia
                    component="img"
                    image={`https:\/\/s3.amazonaws.com\/${bucketName}\/generated_2\/${image.name}`}
                    alt={image.title}
                  />
                )}
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div">
                    <a
                      className="App-link"
                      href={image.source_url}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {image.title}
                    </a>
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </div>
        ))}
      </div>
      {openImage !== null && (
        <Modal
          className="modal"
          open={isModalOpen}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box>
            <Card className="card">
              <CardActionArea
                href={openImage.source_url}
                target="_blank"
                rel="noopener noreferrer"
              >
                <CardMedia
                  component="img"
                  image={`https:\/\/s3.amazonaws.com\/${bucketName}\/generated\/${openImage.name}`}
                  alt={openImage.title}
                />
                <CardContent>
                  <Typography gutterBottom variant="h5" component="div" align='center'>
                    {openImage.title}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Box>
        </Modal>
      )}
    </div>
  );
}

App.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      title: PropTypes.string,
      source_url: PropTypes.string,
      id: PropTypes.string,
      name: PropTypes.string,
    })
  ),
  isModalOpen: PropTypes.bool,
  openImage: PropTypes.shape({
    title: PropTypes.string,
    source_url: PropTypes.string,
    id: PropTypes.string,
    name: PropTypes.string,
  }),
  bucketName: PropTypes.string,
  handleOpen: PropTypes.func,
  handleClose: PropTypes.func,
}

export default App;
